import { css } from "styled-components";

const device = {
  md: "1000px",
  sm: "500px",
};

export const media = {
  md: (...args) => css`
    @media (max-width: ${device.md}) {
      ${css(...args)};
    }
  `,
  sm: (...args) => css`
    @media (max-width: ${device.sm}) {
      ${css(...args)};
    }
  `,
};
