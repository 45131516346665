import {
  ContactContentWrapper,
  ContactWrapper,
} from "../contact/Contact.styles";
import {
  ImageContent,
  VideoContentEffects,
  VideoWrapper,
} from "../landing/Landing.styles";
import Information from "../../components/information/Information";
import InformationExpandable from "../../components/information/InformationExpandable";
import Service from "../../components/service/Service";
// Media
import consultingImage from "../../images/consulting.png";
import i10 from "../../images/10.png";
import i15 from "../../images/15.png";
import i16 from "../../images/16.png";
import i17 from "../../images/17.png";
import i18 from "../../images/18.png";
import i19 from "../../images/19.png";
import i20 from "../../images/20.png";
import i21 from "../../images/21.png";
import i22 from "../../images/22.png";
import i23 from "../../images/23.png";
import i24 from "../../images/24.png";

const Consulting = () => {
  return (
    <ContactWrapper>
      <VideoWrapper style={{ height: "610px" }}>
        <VideoContentEffects
          style={{ backgroundColor: "rgba(255,255,255,0.3)" }}
        >
          <ImageContent style={{ background: "unset" }}>
            <h1>SR&ED Consulting</h1>
          </ImageContent>
        </VideoContentEffects>
        <img src={consultingImage} />
      </VideoWrapper>
      <ContactContentWrapper>
        <Information>
          <h1>Helping Our Clients Secure SR&ED Tax Incentives</h1>
          <p>
            Every day you work hard to out-maneuver the competition. You develop
            new products, enhance processes, face new challenges and find
            creative solutions to your customers problems. These activities may
            quality for the Government of Canada’s Scientific Research and
            Experimental Development (“SR&ED”) incentive program. SR&ED is an
            incentive program through which the Canadian government encourages
            businesses to conduct research and development. SR&ED is available
            to both small and large businesses in all industries in Canada.
          </p>
        </Information>
        <InformationExpandable>
          <h1>Recover 35%+ Of Eligible Costs</h1>
          <p>
            The SR&ED program entitles companies to claim a material 35%+ of
            eligible expenditure for a cash payout or tax reduction. Canadian
            Controlled Private Corporations (CCPC’s) receive preferential
            treatment under the SR&ED program. For example when including
            overhead recovery and , in Ontario a CCPC’ may recover:
            <ul>
              <li>73% of qualifying labour expenditures</li>
              <li>44% of qualifying material expenditures</li>
              <li>44% of qualifying contractor expenditures</li>
            </ul>
            The SR&ED incentive program is not just available to research
            intensive industries. The SR&ED program is generally available to
            any Canadian company which faces uncertainties in overcoming
            technical challenges where certain criteria are met. Whenever you
            create new or improve existing products, processes, principles,
            methodologies or materials you may be eligible for SR&ED if certain
            criteria are met. Our team of experts can help you decipher the
            SR&ED program and submit claims with a high probability of success.
            Please contact us to learn more or visit our FAQ page for more
            details.
          </p>
        </InformationExpandable>
        <Information>
          <h1>Many Types Of Work May Qualify For SR&ED</h1>
          <p>
            SR&ED-eligible projects encompass efforts aimed at addressing
            technological challenges or uncertainties when existing solutions
            are inadequate. To qualify, such work must enhance scientific or
            technological comprehension, tackle uncertainties in these domains,
            and involve a methodical investigation conducted by qualified
            personnel.
          </p>
          <Service title="Tasks eligible for SR&ED involve" borderless>
            <section>
              <img src={i15} />
              <p>
                Experimental development to create new materials, devices,
                products, or processes (or improve existing ones)
              </p>
            </section>
            <section>
              <img src={i16} />
              <p>
                Applied research conducted with a specific practical application
                in mind
              </p>
            </section>
            <section>
              <img src={i17} />
              <p>
                Basic research to advance scientific knowledge without a
                specific practical application in mindnes)
              </p>
            </section>
            <section>
              <img src={i18} />
              <p>
                Support work (including: engineering, design, operations
                research, mathematical analysis, computer programming, data
                collection, testing, psychological research, etc.)
              </p>
            </section>
          </Service>
        </Information>
        <Information>
          <h1>How can we help?</h1>
          <p>
            Our team of professionals will assist you in deciphering the CRA’s
            rules and navigating your submission. We will help ensure that
            preparing your Scientific Research and Experimental Development
            (SR&ED) claims are hassle-free. Not only will we guide you through
            the initial application process we will also assist in any
            post-filing appeals.
          </p>
          <Service title="Our Process includes" borderless button>
            <section>
              <img src={i10} />
              <p>
                Conduct an initial informal assessment to see if your project
                may qualify for SR&ED
              </p>
            </section>
            <section>
              <img src={i19} />
              <p>
                Interview your staff to identify projects that may meet the CRA
                eligibility criteria
              </p>
            </section>
            <section>
              <img src={i20} />
              <p>Gather data for SR&ED reports from your team efficiently</p>
            </section>
            <section>
              <img src={i21} />
              <p>
                Prepare a technical SR&ED report which summarizes how your claim
                meets the SR&ED eligibility criteria
              </p>
            </section>
            <section>
              <img src={i22} />
              <p>
                Complete all prescribed CRA forms required for a successful
                SR&ED claim
              </p>
            </section>
            <section>
              <img src={i23} />
              <p>
                Coordinate with your accountant to identify costs associated
                with the projects
              </p>
            </section>
            <section>
              <img src={i24} />
              <p>Provide value added advice on your overall SR&ED strategy</p>
            </section>
          </Service>
        </Information>
      </ContactContentWrapper>
    </ContactWrapper>
  );
};

export default Consulting;
