import styled from "styled-components";
import { LinkButton } from "../../pages/landing/Landing.styles";
import { media } from "../../styles/sizes";

export const QuestionWrapper = styled.div`
  transition: 0.5s all;
  background-color: ${(props) => (props.isOpened ? "white" : "#47BFFF")};
  border-radius: 30px;
  padding: 40px 50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 20px;
  > p {
    font-size: 24px;
    > ul {
      list-style-position: inside;
      > li {
        line-height: 2.3rem;
      }
    }

    ${media.sm`
      font-size: 18px;
    `}
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 40px;
  > h1 {
    max-width: 70%;
    font-size: 32px;
    font-weight: 500;
    color: ${(props) => (props.isOpened ? "unset" : "white")};
    ${media.sm`
      font-size: 22px;
      max-width: 100%;
    `}
  }

  ${media.sm`
    flex-direction: column;
  `}
`;

export const OpenButton = styled(LinkButton)`
  cursor: pointer;
  background-color: ${(props) => (props.isOpened ? "#47bfff" : "white")};
  transition: 0.5s all;
  > p {
    color: ${(props) => (props.isOpened ? "white" : "#47bfff")};
  }
  > svg {
    transition: 0.5s all;
    transform: rotate(${(props) => (props.isOpened ? 180 : 0)}deg);
  }
  path {
    fill: ${(props) => (props.isOpened ? "white" : "#47bfff")};
  }

  ${media.sm`
    width: 100%;
  `}
`;
