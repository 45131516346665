import styled from "styled-components";
import { media } from "../../styles/sizes";

export const LandingDiv = styled.div``;
export const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  z-index: 1;
  > video,
  > img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    outline: none;
    border: none;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    z-index: -1;
  }
`;
export const VideoContentEffects = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
`;
export const VideoContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  gap: 25px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.2) 69.27%,
    rgba(255, 255, 255, 0) 100%
  );
  background-blend-mode: overlay;
  > h1 {
    font-size: 40px;
    font-weight: 500;
    text-align: center;
    ${media.sm`
      font-size: 25px;
    `}
  }
  > p {
    max-width: 700px;
    font-size: 24px;
    text-align: center;
    ${media.sm`
      font-size: 20px;
    `}
  }
`;

export const ImageContent = styled(VideoContent)`
  background: unset;
  background-blend-mode: unset;
  > h1 {
    text-shadow: 0px 4px 15px rgba(28, 28, 28, 0.5);
  }
`;
export const Spacer = styled.div`
  width: 100px;
  height: 2px;
  background-color: #47bfff;
  border-radius: 2em;
`;
export const LinkButton = styled.div`
  border-radius: 15px;
  padding: 15px 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #47bfff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: fit-content;
  text-align: center;
  > a,
  p {
    text-decoration: none;
    color: white;
    font-size: 24px;
    font-weight: 500;
    ${media.sm`
      font-size: 20px;
    `}
  }
`;
export const ScrollDown = styled.img`
  height: 64px;
`;
export const GetInTouchWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-size: cover;
  background-position: center;
  ${media.sm`
    height: 500px !important;
  `}
`;
export const GetInTouchContentWrapper = styled.div`
  margin-top: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  height: fit-content;

  ${media.md`
    flex-direction: column;
    gap: 20px;
  `}
  ${media.sm`
  margin-top: 40px !important;
  `}
`;
export const GetInTouchContent = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  padding: 40px;
  border-radius: 20px;
  max-width: 900px;
  > p {
    font-size: 25px;
    font-weight: 500;
    ${media.sm`
    font-size: 20px;
  `}
  }
  ${media.md`
    flex-direction: column;
    gap: 20px;
    backdrop-filter: blur(5px);
  `}
`;
