import styled from "styled-components";
import { media } from "../../styles/sizes";

export const InformationWrapper = styled.div`
  background-color: white;
  border-radius: 30px;
  padding: 40px 50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 20px;
  > h1 {
    font-size: 36px;
    font-weight: 500;
    ${media.sm`
      font-size: 25px;
    `}
  }
  > p {
    font-size: 24px;
    ${media.sm`
      font-size: 20px;
    `}
  }
`;
