import {
  ImageContent,
  VideoContentEffects,
  VideoWrapper,
} from "../landing/Landing.styles";
import { ServicesInner, ServicesWrapper } from "./Services.styles";

// Media
import servicesImg from "../../images/services.png";
import Service from "../../components/service/Service";
import i1 from "../../images/1.png";
import i2 from "../../images/2.png";
import i3 from "../../images/3.png";
import i4 from "../../images/4.png";
import i5 from "../../images/5.png";
import i6 from "../../images/6.png";
import i7 from "../../images/7.png";
import i8 from "../../images/8.png";
import i9 from "../../images/9.png";
import i10 from "../../images/10.png";
import i11 from "../../images/11.png";
import i12 from "../../images/12.png";
import i13 from "../../images/13.png";
import i14 from "../../images/14.png";

const Services = () => {
  return (
    <ServicesWrapper>
      <VideoWrapper style={{ height: "610px" }}>
        <VideoContentEffects
          style={{ backgroundColor: "rgba(255,255,255,0.4)" }}
        >
          <ImageContent style={{ background: "unset" }}>
            <h1>Our Services</h1>
          </ImageContent>
        </VideoContentEffects>
        <img src={servicesImg} />
      </VideoWrapper>
      <ServicesInner>
        <Service title="Tax Planning and Preparation Services">
          <section>
            <img src={i4} />
            <p>
              Scientific Research and Experimental Development (“SR&ED”)
              consulting services
            </p>
          </section>
          <section>
            <img src={i1} />
            <p>Individual & Corporate Taxation</p>
          </section>
          <section>
            <img src={i3} />
            <p>
              Employee and payroll services including Statement of Remuneration
              Paid (T4) , Canada Pension Plan (“CPP”) remission, Employment
              Insurance (“EI”) premium submission, and Records of Employment
              (“ROE”)
            </p>
          </section>
          <section>
            <img src={i2} />
            <p>Trust Tax preparation</p>
          </section>
        </Service>
        <Service title="Assurance and Financial Statement Reporting Services">
          <section>
            <img src={i5} />
            <p>
              Financial statement preparation (income statement, balance sheet,
              statement of cash-flows), including Notices to Reader (“NTR”)
            </p>
          </section>
          <section>
            <img src={i6} />
            <p>Accounting system implementation</p>
          </section>
          <section>
            <img src={i7} />
            <p>Financial statement audit and review</p>
          </section>
          <section>
            <img src={i8} />
            <p>Management Disclosure and Analysis (“MD&A”) preparation</p>
          </section>
          <section>
            <img src={i9} />
            <p>Bookkeeper training</p>
          </section>
        </Service>
        <Service button title="Business Consulting Services">
          <section>
            <img src={i10} />
            <p>
              Incorporation, draft corporate articles (articles of
              incorporation, articles of amendment, articles of dissolution
              etc.), business name registration and other corporate services
            </p>
          </section>
          <section>
            <img src={i11} />
            <p>
              Financial Planning and Analysis (“FP&A”), including financial
              models, budgets and forecasts
            </p>
          </section>
          <section>
            <img src={i12} />
            <p>
              Business Development Canada (“BDC”) loan Business Cases and
              Financial Forecasts
            </p>
          </section>
          <section>
            <img src={i8} />
            <p>
              Strategic and Operations consulting including business plan
              preparation, new market entry, and financing proposals
            </p>
          </section>
          <section>
            <img src={i13} />
            <p>
              Marketing and branding services including logos design, business
              card design, and website design
            </p>
          </section>
          <section>
            <img src={i14} />
            <p>
              Applications for government grants, export subsidies, loans and
              other business support programs
            </p>
          </section>
        </Service>
      </ServicesInner>
    </ServicesWrapper>
  );
};

export default Services;
