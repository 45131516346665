import { Link } from "react-router-dom";
import { LinkButton } from "../../pages/landing/Landing.styles";
import { Bottom, ServiceWrapper, Top, VerticalSpacer } from "./Service.styles";

const Service = ({ children, title, button, borderless = false }) => {
  return (
    <ServiceWrapper borderless={borderless}>
      <Top>{title}</Top>
      <Bottom>
        {children}
        <VerticalSpacer />
      </Bottom>
      {button && (
        <LinkButton>
          <Link to="/contact">Contact us to learn more</Link>
        </LinkButton>
      )}
    </ServiceWrapper>
  );
};

export default Service;
