import styled from "styled-components";
import { Spacer } from "../../pages/landing/Landing.styles";
import { media } from "../../styles/sizes";

export const ServiceWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 60px;
  width: 100%;
  max-width: ${(props) => (props.borderless ? "unset" : "1200px")};
  border-radius: 30px;
  padding: ${(props) => (props.borderless ? "0" : "40px 50px")};
  box-shadow: ${(props) =>
    props.borderless ? "unset" : "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"};
  background-color: white;
`;
export const Top = styled.h1`
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  ${media.sm`
    font-size: 22px;
  `}
`;
export const Bottom = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  gap: 30px;
  > section {
    width: calc(50% - 15px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    > img {
      ${media.sm`
        height: 42px;
      `}
    }
    > p {
      font-size: 24px;
      text-align: center;
      max-width: calc(100% - 40px);
      ${media.sm`
        font-size: 18px;
      `}
    }
    ${media.sm`
        width: 100%;
    `}
  }
`;

export const VerticalSpacer = styled(Spacer)`
  top: 0;
  position: absolute;
  left: calc(50% - 2.5px);
  width: 5px;
  height: 100%;
  ${media.sm`
    display: none;
  `}
`;
