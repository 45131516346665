import {
  GetInTouchContent,
  GetInTouchContentWrapper,
  GetInTouchWrapper,
  LandingDiv,
  LinkButton,
  ScrollDown,
  Spacer,
  VideoContent,
  VideoWrapper,
} from "./Landing.styles";
import { Link } from "react-router-dom";
import { media } from "../../styles/sizes";
import styled from "styled-components";

// Media
import landingVideo from "../../images/landing.mp4";
import scrolldownSvg from "../../images/scrolldown.svg";
import landingEndImage from "../../images/landing_end.jpg";

const VideoWrapperOverride = styled(VideoWrapper)`
  ${media.sm`
    justify-content: flex-start;
    padding-top: 100px;
  `}
`;

const Landing = () => {
  return (
    <LandingDiv>
      <VideoWrapperOverride style={{ height: "880px" }}>
        <VideoContent>
          <h1>Gabriel Calman CPA, CA</h1>
          <Spacer style={{ width: "225px" }} />
          <p>
            Assurance, Taxation & Advisory Services: Specializing in Scientific
            Research and Experimental Development (“SR&ED”) consulting
          </p>
          <LinkButton>
            <Link to="/contact">Contact us to learn more</Link>
          </LinkButton>
        </VideoContent>
        <div>
          <ScrollDown src={scrolldownSvg} />
        </div>
        <video playsInline autoPlay muted loop>
          <source src={landingVideo} type="video/mp4" />
        </video>
      </VideoWrapperOverride>
      <GetInTouchWrapper
        style={{ backgroundImage: `url(${landingEndImage})`, height: "700px" }}
      >
        <GetInTouchContentWrapper>
          <GetInTouchContent>
            <p>
              Gabriel Calman & Associates is a is a full service Chartered
              Professional Accountant (“CPA”) firm. Our skilled team offers
              clients taxation, assurance and advisory services. We are
              specialized in SR&ED Tax Consulting.
            </p>
          </GetInTouchContent>
          <LinkButton>
            <Link to="/contact">Get In Touch</Link>
          </LinkButton>
        </GetInTouchContentWrapper>
      </GetInTouchWrapper>
    </LandingDiv>
  );
};

export default Landing;
