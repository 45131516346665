import styled from "styled-components";
import { media } from "../../styles/sizes";

export const ServicesWrapper = styled.div``;
export const ServicesInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 100px 20px;
  gap: 70px;
  ${media.sm`
    padding: 50px 20px;
  `}
`;
