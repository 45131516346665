import {
  ContactContentWrapper,
  ContactWrapper,
} from "../contact/Contact.styles";
import {
  ImageContent,
  VideoContentEffects,
  VideoWrapper,
} from "../landing/Landing.styles";
import Question from "../../components/question/Question";
import styled from "styled-components";

// Media
import faqImage from "../../images/faq.png";

const QuestionContentWrapper = styled(ContactContentWrapper)`
  gap: 50px;
`;

const Faq = () => {
  return (
    <ContactWrapper>
      <VideoWrapper style={{ height: "610px" }}>
        <VideoContentEffects
          style={{ backgroundColor: "rgba(255,255,255,0.3)" }}
        >
          <ImageContent style={{ background: "unset" }}>
            <h1>Frequently Asked Questions</h1>
          </ImageContent>
        </VideoContentEffects>
        <img src={faqImage} />
      </VideoWrapper>
      <QuestionContentWrapper>
        <Question title="What Is The Scientific Research & Experimental Development (SR&ED) Program?">
          <p>
            Scientific Research & Experimental Development (SR&ED), or commonly
            pronounced as “Shred”, is a Canadian federal tax incentive program
            administered by the Canada Revenue Agency (“CRA”). SR&ED is intended
            to encourage Canadian businesses to conduct research and development
            (R&D) to create new or improve existing products, processes,
            principles, methodologies or materials. It is the largest single
            source of federal government support for industrial R&D. SR&ED may
            be used by businesses of all sizes and in all sectors of the
            economy. There are also a range of provincial programs which work in
            tandem with SR&ED to complement the federal program. Please contact
            us to learn more.
          </p>
        </Question>
        <Question title="But We Don’t Do Research & Development (R&D) Work?">
          <p>
            The SR&ED program’s research & development definition is somewhat
            different than what most individuals think of when they hear R&D.
            R&D means that you are trying to advance the state of your
            technology through a systematic approach while overcoming technical
            challenges. This means that SR&ED is not just available to research
            intensive industries; rather it is available to any company which
            faces technical challenges. The SR&ED program takes into account
            information available in the public domain (i.e. searches on Google,
            etc.) as well as the context of your company. If you can’t find a
            solution to your technical problem in the public domain then this is
            where your SR&ED work may begin. Certain technological challenges
            arise from constraints faced by your specific situation due to
            legacy support issues or legacy equipment used. Overcoming these
            types of technical challenges in a systematic way could make the
            work eligible for SR&ED tax credits. Please contact us to learn
            more.
          </p>
        </Question>
        <Question title="What Type Of Projects Qualify?">
          <p>
            Any work undertaken to resolve a technological challenge or
            uncertainty where you couldn’t get the solution in the public domain
            (from a Google search, white paper, etc.) as long as it is
            undertaken in a systematic way by qualified personnel (not a
            training program). This means that any time you are taking a
            technical risk in order to advance your technical baseline (to meet
            business requirements or improve your product or process), even if
            it is for a marginal improvement, you may qualify for SR&ED tax
            credits.
          </p>
          <p>
            To qualify for the SR&ED program, work must advance the
            understanding of scientific relations or technologies, address
            scientific or technological uncertainty, and incorporate a
            systematic investigation by qualified personnel. Work that qualifies
            for SR&ED tax credits includes:
            <ul>
              <li>
                Experimental development to achieve technological advancement to
                create new materials, devices, products, or processes, or
                improve existing ones;
              </li>
              <li>
                Applied research to advance scientific knowledge with a specific
                practical application in view;
              </li>
              <li>
                Basic research to advance scientific knowledge without a
                specific practical application in view
              </li>
              <li>
                Support work in engineering, design, operations research,
                mathematical analysis, computer programming, data collection,
                testing, or psychological research, but only if the work is
                commensurate with, and directly supports, the eligible
                experimental development, or applied or basic research.
              </li>
            </ul>
          </p>
        </Question>
        <Question title="What Are The Qualification Criteria?">
          <p>
            The three primary criteria for determining if work is eligible for
            SR&ED:
            <ul>
              <li>
                <strong>Technological Advancement.</strong> The search carried
                out in the experimental development activity must generate
                information that advances your understanding of the underlying
                technologies. In a business context, this means that when a new
                or improved material, device, product or process is created, it
                must embody a technological advancement in order to be eligible.
                In other words the work must attempt to increase the technology
                base or level from where it was at the beginning of the project.
              </li>
              <li>
                <strong>Technological Uncertainty.</strong> Technological
                obstacles/uncertainties are the technological problems or
                unknowns that cannot be overcome by applying the techniques,
                procedures and data that are generally accessible to competent
                professionals in the field.
              </li>
              <li>
                <strong>Technical Content.</strong> A systematic investigation
                entails going from identification and articulation of the
                scientific or technological obstacles/uncertainties, to
                hypothesis formulation, through testing by experimentation or
                analysis, to the statement of logical conclusions. In a business
                context, this requires that the objectives of the scientific
                research or experimental development work must be clearly stated
                at an early stage in the evolution of the project, and the
                method of addressing the scientific or technological
                obstacle/uncertainty by experimentation or analysis must be
                clearly set out.
              </li>
            </ul>
          </p>
        </Question>
        <Question title="What Kind Of Expenditures Qualify?">
          <p>
            A number of different types of expenditures qualify as SR&ED
            expenditures as follows:
            <ul>
              <li>
                Salaries for those directly involved in SR&ED eligible work
              </li>
              <li>
                Subcontractor costs for directly involved SR&ED eligible work
              </li>
              <li>
                Material costs required to achieve your technological
                advancement(s)
              </li>
              <li>
                Overhead for office expenses and miscellaneous overhead costs
                (standard 55% rate applied to salaries)
              </li>
            </ul>
          </p>
        </Question>
        <Question title="How Much Money Can I Expect?">
          <p>
            <ul>
              <li>
                <strong>Federal SR&ED Investment Tax Credit:</strong> 15%
                federal tax credit for all qualifying R&D costs for eligible
                activities carried on in Canada. The credit rate is increased to
                35% for small Canadian-controlled private corporations (on
                expenditures up to $3M per year,). This 35% credit is fully
                refundable.
              </li>
              <li>
                <strong>Provincial SR&ED Incentives:</strong> Tax credits
                ranging from 4.5% to 37.5% depending upon the provincial
                jurisdiction.
              </li>
            </ul>
          </p>
        </Question>
        <Question title="What Is The Process To Submit a SR&ED claim?">
          <p>
            The process for submitting a SR&ED claim with assistance from
            outside consultants includes:
            <ul>
              <li>
                Initial informal assessment to see if your projects may qualify
                for SR&ED
              </li>
              <li>
                Interviews are held to identify projects that may meet the CRA
                eligibility criteria
              </li>
              <li>
                Data is gathered for SR&ED reports (through phone calls and
                email communication)
              </li>
              <li>
                Prepare a technical SR&ED report which summarizes how your claim
                meets the SR&ED eligibility criteria; a draft report is prepared
                which is then reviewed by the company to ensure it is accurate;
                changes are made to the report based on company feedback
              </li>
              <li>
                Coordinate with your accountant to identify costs associated
                with the projects (i.e. review T2 Corporate Tax Return and
                financial information)
              </li>
              <li>
                Complete all prescribed CRA forms required for a successful
                SR&ED claim
              </li>
              <li>
                Submit the SR&ED claim to the CRA and monitor for acceptance
              </li>
              <li>
                Success fees are paid to the consultant only after the SR&ED
                claim is accepted by the CRA (if your SR&ED claim is not
                successful, you don’t pay anything).
              </li>
            </ul>
          </p>
          <p>
            Generally speaking, ~ 75% of SR&ED claims are accepted without
            further enquiry by the CRA. Sometimes there may be a call with CRA
            where they ask questions on your report (such as a call with CRA’s
            First-Time Claimant Advisory Services team). Should these calls
            occur, our team will join you for these calls to help manage the CRA
            communication. Please contact us to learn more.
          </p>
        </Question>
      </QuestionContentWrapper>
    </ContactWrapper>
  );
};

export default Faq;
