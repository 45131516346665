import styled from "styled-components";
import { InformationWrapper } from "./Information.styles";
import { LinkButton } from "../../pages/landing/Landing.styles";
import { useState } from "react";
import { media } from "../../styles/sizes";

const InformationExpandableWrapper = styled(InformationWrapper)`
  align-items: flex-end;
  > h1 {
    width: 100%;
    ${media.sm`
      font-size: 25px;
    `}
  }
  > p {
    position: relative;
    height: 100%;
    max-height: ${(props) => (props.expanded ? "unset" : "200px")};
    overflow: hidden;
    gap: 20px;
    display: flex;
    flex-direction: column;
    > ul {
      list-style-position: inside;
      > li {
        line-height: 2.3rem;
      }
    }
    ${(props) =>
      !props.expanded &&
      `
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            #fff 100%
          );
        }
      `};
    ${media.sm`
      font-size: 20px;
    `}
  }
`;

const ExpandButton = styled(LinkButton)`
  > p {
    font-size: 24px;
    font-weight: 500;
    ${media.sm`
      font-size: 18px;
    `}
  }
  > svg {
    transition: 0.5s all;
    transform: rotate(${(props) => (props.expanded ? 180 : 0)}deg);
    ${media.sm`
      height: 14px;
    `}
  }
`;

const InformationExpandable = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <InformationExpandableWrapper expanded={isExpanded}>
      {children}
      <ExpandButton onClick={handleClick} expanded={isExpanded}>
        <p>{isExpanded ? "Close" : "Read More"}</p>
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.2321 14.5C9.46225 15.8333 7.53775 15.8333 6.76795 14.5L0.272758 3.25C-0.497042 1.91667 0.465208 0.25 2.00481 0.25L14.9952 0.25C16.5348 0.25 17.497 1.91667 16.7272 3.25L10.2321 14.5Z"
            fill="white"
          />
        </svg>
      </ExpandButton>
    </InformationExpandableWrapper>
  );
};

export default InformationExpandable;
