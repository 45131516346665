import { Link } from "react-router-dom";
import {
  FooterBottom,
  FooterButton,
  FooterDiv,
  FooterInner,
  FooterTop,
  Left,
  Right,
  RightMenu,
} from "./Footer.styles";

const Footer = () => {
  return (
    <FooterDiv>
      <FooterInner>
        <FooterTop>
          <Left>
            <h1>Gabriel Calman CPA, CA</h1>
            <p>SR&ED Consulting, Assurance, Taxation & Advisory</p>
          </Left>
          <Right>
            {/* <FooterButton><Link to="">Let's Talk!</Link></FooterButton> */}
          </Right>
        </FooterTop>
        <FooterBottom>
          <Left>
            <p>Copyright ©2023 All rights reserved | Gabriel Calman CPA, CA</p>
          </Left>
          <RightMenu onClick={() => window.scrollTo(0, 0)}>
            <Link to="/">Home</Link>
            <Link to="/services">Services</Link>
            <Link to="/consulting">SR&ED Consulting</Link>
            <Link to="/faq">FAQ</Link>
            <Link to="/contact">Contact Us</Link>
          </RightMenu>
        </FooterBottom>
      </FooterInner>
    </FooterDiv>
  );
};

export default Footer;
