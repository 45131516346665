import {
  ContactContentWrapper,
  ContactInner,
  ContactMethods,
  ContactWrapper,
} from "./Contact.styles";

// Media
import contactUsImage from "../../images/contactus.png";
import {
  ImageContent,
  LinkButton,
  VideoContentEffects,
  VideoWrapper,
} from "../landing/Landing.styles";
import { Top } from "../../components/service/Service.styles";

const Contact = () => {
  return (
    <ContactWrapper>
      <VideoWrapper style={{ height: "610px" }}>
        <VideoContentEffects
          style={{ backgroundColor: "rgba(255,255,255,0.4)" }}
        >
          <ImageContent style={{ background: "unset" }}>
            <h1>Contact Us</h1>
          </ImageContent>
        </VideoContentEffects>
        <img src={contactUsImage} />
      </VideoWrapper>
      <ContactContentWrapper>
        <ContactInner>
          <Top>Let’s Talk</Top>
          <p>
            We look forward to chatting with you on how we can help your
            business can benefit from the Scientific Research & Experimental
            Development (SR&ED) program
          </p>
          <ContactMethods>
            <LinkButton>
              <a href="mailto:technical@gabrielcalman.ca">
                Email us at technical@gabrielcalman.ca
              </a>
            </LinkButton>
            <LinkButton>
              <a href="tel:6475024093">Please call us at 647-502-4093</a>
            </LinkButton>
          </ContactMethods>
        </ContactInner>
      </ContactContentWrapper>
    </ContactWrapper>
  );
};

export default Contact;
