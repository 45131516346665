import styled from "styled-components";
import { LinkButton } from "../../pages/landing/Landing.styles";
import { media } from "../../styles/sizes";

export const FooterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px 20px;
  background-color: #1c1c1c;

  h1,
  p {
    color: white;
  }
`;

export const FooterInner = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 60px;
`;

export const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${media.md`
    justify-content: center;
  `}
`;

export const Left = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  > h1 {
    font-size: 32px;
    font-weight: 500;
  }
  > p {
    font-size: 20px;
    font-weight: normal;
  }
`;

export const Right = styled(Left)``;

export const FooterBottom = styled(FooterTop)`
  p {
    font-size: 16px;
    font-weight: normal;
  }
  ${media.md`
    flex-direction: column;
    gap: 20px;
  `}
`;

export const RightMenu = styled(Right)`
  flex-direction: row;
  gap: 20px;
  > a {
    color: white;
    text-decoration: none;
    font-size: 16px;
  }
  ${media.sm`
    display: none;
  `}
`;

export const FooterButton = styled(LinkButton)`
  box-shadow: unset;
  padding: 15px 30px;
  > a {
    font-size: 20px;
  }
`;
