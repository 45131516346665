import styled from "styled-components";
import { media } from "../../styles/sizes";

export const HeaderDiv = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  background-color: rgba(28, 28, 28, 0.8);
  z-index: 10;
  transition: 0.5s all;
  &.scrolled {
    height: 50px;
    h1 {
      font-size: 30px;
      ${media.sm`
        font-size: 20px;
      `}
    }
    a {
      font-size: 17px;
    }
  }
`;

export const Links = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  padding: 0 20px;
  width: 100%;
  svg {
    height: 100%;
    cursor: pointer;
    display: none;
  }

  ${media.md`
    > svg {
      display: unset;
    }
  `}
  ${media.sm`
    >svg {
      height: 40px;
    }
  `}
`;

export const Left = styled.div`
  > h1 {
    transition: 0.5s all;
    font-size: 32px;
    font-weight: 500;
    color: white;
    ${media.sm`
    font-size: 22px;
    `}
  }
`;
export const Right = styled(Left)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > a {
    text-decoration: none;
    color: white;
    transition: 0.5s all;
    padding: 0 15px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    &:hover {
      background-color: rgba(28, 28, 28, 0.8);
    }
    ${media.sm`
      font-size: 15px;
    `}
  }
  h1 {
    display: none;
  }

  &.open {
    left: 0;
  }

  ${media.md`
    position: absolute;
    top: 0;
    left: 100%;
    width: 100vw;
    height: 100vh;
    z-index: 20;
    background: rgba(28, 28, 28, 0.9);
    padding: 30px;
    backdrop-filter: blur(10px);
    flex-direction: column;
    transition: 0.5s all;
    > div {
      width: 100vw;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      > h1 {
      display: unset;
      color: white;
      }
      > svg {
        height: 54px;
        position: absolute;
        display: unset;
        right: 30px;
      }
    }
    
    > a {
      font-size: 30px !important;
      width: 100vw;
    }
  `}
  ${media.sm`
    a {
      font-size: 20px !important;
    }
    h1 {
      font-size: 20px;
    }
    svg {
        height: 32px !important;
    }
  `}
`;
