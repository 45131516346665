import { Link } from "react-router-dom";
import { HeaderDiv, Left, Links, Right, Scrolled } from "./Header.styles";
import { useRef, useState } from "react";

const Header = () => {
  const headerRef = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useState(() => {
    window.addEventListener("scroll", () => {
      if (!headerRef.current) return;
      if (window.scrollY == 0) headerRef.current.classList.remove("scrolled");
      if (window.scrollY != 0) headerRef.current.classList.add("scrolled");
    });
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  };

  return (
    <HeaderDiv ref={headerRef}>
      <Links>
        <Left>
          <h1>Gabriel Calman CPA, CA</h1>
        </Left>

        <svg
          onClick={toggleMenu}
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          id="menu"
        >
          <g data-name="Layer 2">
            <g data-name="menu">
              <rect width="18" height="2" x="3" y="11" rx=".95" ry=".95"></rect>
              <rect width="18" height="2" x="3" y="16" rx=".95" ry=".95"></rect>
              <rect width="18" height="2" x="3" y="6" rx=".95" ry=".95"></rect>
            </g>
          </g>
        </svg>
        <Right
          onClick={() => {
            window.scrollTo(0, 0);
            toggleMenu();
          }}
          className={isMenuOpen && "open"}
        >
          <div>
            <h1>Gabriel Calman CPA, CA</h1>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              id="close"
              fill="white"
            >
              <path d="M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
            </svg>
          </div>
          <Link to="/">Home</Link>
          <Link to="/services">Services</Link>
          <Link to="/consulting">SR&ED Consulting</Link>
          <Link to="/faq">FAQ</Link>
          <Link to="/contact">Contact Us</Link>
        </Right>
      </Links>
    </HeaderDiv>
  );
};

export default Header;
