import { AppDiv } from "./App.styles";
import { Outlet } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import ScrollToTop from "./components/ScrollToTop";
import { useState } from "react";

const App = () => {
  const [waitForComponents, setWait] = useState(Math.random())

  if (waitForComponents < 0.5) return <></>;
  return (
    <AppDiv>
      <ScrollToTop />
      <Header />
      <Outlet />
      <Footer />
    </AppDiv>
  );
};

export default App;
