import { useState } from "react";
import { OpenButton, QuestionWrapper, Top } from "./Question.styles";

const Question = ({ children, title }) => {
  const [isOpened, setIsOpened] = useState(false);

  const handleClick = () => {
    setIsOpened(!isOpened);
  };

  return (
    <QuestionWrapper isOpened={isOpened}>
      <Top isOpened={isOpened}>
        <h1>{title}</h1>
        <OpenButton onClick={handleClick} isOpened={isOpened}>
          <p>{isOpened ? "Close" : "Read More"}</p>
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.2321 14.5C9.46225 15.8333 7.53775 15.8333 6.76795 14.5L0.272758 3.25C-0.497042 1.91667 0.465208 0.25 2.00481 0.25L14.9952 0.25C16.5348 0.25 17.497 1.91667 16.7272 3.25L10.2321 14.5Z"
              fill="#47BFFF"
            />
          </svg>
        </OpenButton>
      </Top>
      {isOpened && children}
    </QuestionWrapper>
  );
};

export default Question;
