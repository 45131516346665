import styled from "styled-components";
import { ServiceWrapper } from "../../components/service/Service.styles";
import { media } from "../../styles/sizes";

export const ContactWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
export const ContactContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  padding: 100px 20px;
  gap: 70px;
  ${media.sm`
    padding: 50px 20px;
  `}
`;
export const ContactInner = styled(ServiceWrapper)`
  gap: 40px;
  > p {
    max-width: 500px;
    text-align: center;
    font-size: 24px;
  }
  > div {
    padding: 15px 30px;
  }
`;
export const ContactMethods = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
`;
